import _vfileMessage from "vfile-message";
import _core from "./core.js";
var exports = {};
var VMessage = _vfileMessage;
var VFile = _core;
exports = VFile;
VFile.prototype.message = message;
VFile.prototype.info = info;
VFile.prototype.fail = fail; // Create a message with `reason` at `position`.
// When an error is passed in as `reason`, copies the stack.

function message(reason, position, origin) {
  var message = new VMessage(reason, position, origin);

  if (this.path) {
    message.name = this.path + ":" + message.name;
    message.file = this.path;
  }

  message.fatal = false;
  this.messages.push(message);
  return message;
} // Fail: creates a vmessage, associates it with the file, and throws it.


function fail() {
  var message = this.message.apply(this, arguments);
  message.fatal = true;
  throw message;
} // Info: creates a vmessage, associates it with the file, and marks the fatality
// as null.


function info() {
  var message = this.message.apply(this, arguments);
  message.fatal = null;
  return message;
}

export default exports;